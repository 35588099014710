import Vue from 'vue';

const updateOnlineStatus = () => {
  online.state = navigator ? navigator.onLine : null;
};

window.addEventListener('online', updateOnlineStatus);
window.addEventListener('offline', updateOnlineStatus);

export const online = Vue.observable({
  state: navigator ? navigator.onLine : null,
});
