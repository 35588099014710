export default {
  pages: {
    boot: {
      lostConnection1: 'Connessione assente',
      lostConnection2: 'Riconnettere il dispositivo alla rete',
      minimumVersionNotReached1: 'Abbiamo aggiornato l\'app con nuove funzionalità',
      minimumVersionNotReached2: 'Scarica la nuova versione dallo store',
      minimumVersionNotReachedCTA: 'Vai allo store',
      versionPrefix: 'Eureka V.',
      versionPostfix: '',
    },
    login: {
      welcomeTitle: 'Benvenuto in Eureka Piclink!',
      welcomeText1: 'Effettua l\'accesso con le tue credenziali.',
      welcomeText2: 'Vivi la nuova esperienza di editoria aumentata e interagisci con i contenuti multimediali.',
      usernameHint: 'Username',
      passwordHint: 'Password',
      loginCTA: 'Accedi',
      signup: 'Crea Account',
      recoverPassword: 'Recupera Password',
      usernameRequired: 'Username obbligatorio',
      passwordRequired: 'Password obbligatoria',
      loginFailed: 'Username o password errati',
    },
    home: {
      noProjects: {
        title: 'La mia libreria',
        noAdded: 'Non hai ancora aggiunto nessun progetto...',
        noAvailable: 'Non hai nessun progetto abilitato',
        addCTA: 'Aggiungi il tuo progetto',
        howToAddCTA: 'Scopri come aggiungere progetti',
        logout: 'Disconnetti',
      },
      projects: {
        title: 'La mia libreria',
        resetPermissions: 'Permessi necessari per utilizzare l\'applicazione. Reimpostarli manualmente dalle impostazioni di sistema.',
        actions: {
          title: 'Azioni su Progetto',
          close: 'Chiudi',
          remove: 'Elimina',
        },
      },
      confirm: {
        remove: {
          title: 'Elimina progetto',
          messagePre: 'Vuoi veramente eliminare il progetto ',
          messagePost: '?',
          okButton: 'Elimina',
          cancelButton: 'Annulla',
        },
        logout: {
          title: 'Logout',
          message: 'Vuoi eseguire il logout?',
          okButton: 'Sì',
          cancelButton: 'No',
        },
      },
    },
    scanProject: {
      actionBar: 'Inquadra MarkerZero',
      camera: {
        success: 'Il progetto è stato riconosciuto',
        danger: 'Immagine non riconosciuta',
      },
    },
    scanResource: {
      camera: {
        success: 'La risorsa è stata trovata',
        danger: 'Immagine non riconosciuta',
      },
    },
    showResource: {
      openButton: 'Apri',
      types: {
        image: 'Immagine',
        video: 'Video',
        pdf: 'Pdf',
        html: 'Pagina web',
        other: 'File',
      },
    },
  },
  modals: {
    scanProject: {
      title: 'Scatta e scopri',
      text: 'Inquadra con la fotocamera le immagini contrassegnate dal mirino, scatta una foto ed esplora nuovi contenuti multimediali.',
      button: 'Ho capito',
      checkbox: 'Non mostrare più',
    },
    scanResource: {
      title: 'Scatta e scopri',
      text: 'Inquadra con la fotocamera le immagini contrassegnate dal mirino, scatta una foto ed esplora nuovi contenuti multimediali.',
      button: 'Ho capito',
      checkbox: 'Non mostrare più',
    },
  },
  components: {
    camera: {
      elaborating: 'Elaboro...',
      lostConnection1: 'Connessione assente',
      lostConnection2: 'Riconnettere il dispositivo alla rete',
      success: 'Riconosciuto',
      danger: 'Non riconosciuto',
    },
  },
};
