<template>
  <DefaultLayout :header="!isLoading">
    <Loading v-if="isLoading" />
  </DefaultLayout>
</template>

<script>

import $appConfig from '@/config/appConfig.js';
import DefaultLayout from './layouts/DefaultLayout.vue';
import Loading from './components/Loading.vue';

export default {
  name: 'app',
  components: {
    DefaultLayout,
    Loading,
  },
  data () {
    return {
      loading: true,

      logged: false,
    };
  },
  computed: {
    isLoading () {
      return this.loading || !this.$route?.name;
    },
  },
  methods: {
    computeHeight () {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  },
  async mounted () {
    window.addEventListener('resize', this.computeHeight);
    this.computeHeight();

    this.$http.get(`${$appConfig.apiBaseUrl}/apps/${$appConfig.appId}/projects`)
      .then(res => {
        const projects = res.data?.projects || [];
        this.$storage.set('projects', projects);
        if (projects.length === 1) {
          const project = projects[0];

          return this.$router.replace({ name: 'scan-resource', params: { projectId: project.id } });
        }
      })
      .catch(() => {
        this.$storage.set('projects', []);
      })
      .finally(() => {
        this.loading = false;
      })
    ;
  },
};

</script>

<style lang="scss"></style>
