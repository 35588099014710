import { online } from '../services/online.js';

let installed = false;

const VueOnline = {};

VueOnline.install = function (Vue) {
  if (!installed) {
    Object.defineProperties(Vue.prototype, {
      $online: {
        get: () => online.state,
      },
    });
  }

  installed = true;
};

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(VueOnline);
}

export default VueOnline;
