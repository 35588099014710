export default [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/pages/Home.vue'),
  },
  {
    path: '/scan/:projectId',
    name: 'scan-resource',
    component: () => import(/* webpackChunkName: "scan" */ '@/views/pages/ScanResource.vue'),
  },
  {
    path: '/scan/:projectId/show',
    name: 'show-resource',
    component: () => import(/* webpackChunkName: "scan" */ '@/views/pages/ShowResource.vue'),
  },

  {
    path: '*',
    redirect: '/',
  },
];
