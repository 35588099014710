/* eslint-disable import/first */
import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { Plugin as VueStorage } from 'vue2-storage';
import { BootstrapVue as VueBootstrap } from 'bootstrap-vue';
import VueOnline from './plugins/VueOnline.js';

import config from './config/appConfig.js';
Vue.prototype.$config = config;

import texts from './config/texts.js';
Vue.prototype.$texts = texts;

Vue.use(VueRouter);
axios.defaults.responseType = 'json';
Vue.use(VueAxios, axios);
Vue.use(VueStorage);
Vue.use(VueBootstrap);
Vue.use(VueOnline);

Vue.config.productionTip = false;

import App from './views/App.vue';
import routes from './config/routes.js';
const router = new VueRouter({
  mode: 'history',
  routes,
});

new Vue({
  ...App,
  router,
}).$mount('#app');

(() => import(/* webpackChunkName: "css-bootstrap" */ './assets/scss/bootstrap.scss'))();
(() => import(/* webpackChunkName: "css-style" */ './assets/scss/style.scss'))();
