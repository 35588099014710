<template>
  <div class="container page-container d-flex flex-column shadow p-0">
    <slot>
      <router-view></router-view>
    </slot>
  </div>
</template>

<script>

export default {
  props: {
    header: {
      type: Boolean,
      default: false,
    },
  },
};

</script>

<style lang="scss">

$min-width: 240px !default;

.page-container {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #fff;

  .page {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: $min-width;

    &__content {
      flex-grow: 1;
    }

    &__header {
      background-color: $headerBackgroundColor;
      height: 60px;
    }

    &.page-fixed {
      .page {
        &__content {
          max-height: 100vh;
          max-height: calc(var(--vh, 1vh) * 100);
        }

        &__header + &__content {
          max-height: 100vh;
          max-height: calc(100vh - 60px);
          max-height: calc(var(--vh, 1vh) * 100 - 60px);
        }
      }
    }
  }
}

</style>
