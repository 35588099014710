export default {
  apiBaseUrl: 'https://api.omark.io/api/v1',
  logBaseUrl: 'https://log.omark.io/api/v1',
  appId: '2eb297e1-d7a2-470e-a1c4-24baa32f213e',
  enableMock: false,

  // Various
  requireVersionCheck: true,
  androidPackageId: null,
  iTunesAppId: null,

  // Login
  enableLogin: true,
  loginSignupUrl: null,
  loginRecoverUrl: 'https://elearning.eureka.srl/wp-login.php?action=lostpassword&redirect_to=https%3A%2F%2Felearning.eureka.srl%2Flogin%2F%3Fmessage%3Dcheckmail',

  // Home
  homeNoProjectsUrl: null,
  homeScanProjectEnabled: false,

  // Scan
  enableFlashToggle: false,
};
